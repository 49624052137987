/* You can add global styles to this file, and also import other style files */

.menu-sidebar {
  background-color: #fff;

  li.menu-item,
  .menu-item {
    a span.menu-title,
    .menu-icon {
      color: #000;
    }
    .menu-icon:hover {
      color: #000;
    }
  }
}

.toast img {
  max-width: 250px;
  max-height: 250px;
  object-fit: contain;
}

.form-range {
  background-color: #35a2eb;
}

.content {
  position: relative;
}

@mixin track() {
  color: #35a2eb;
  background-color: #35a2eb;
}

input.form-range {
  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }
}

.btn-primary {
  background-color: #ff6384;
  border-color: #ff6384;
  &:disabled {
    background-color: #ff6384;
    border-color: #ff6384;
  }
  &:hover {
    background-color: #ff6384;
    border-color: #ff6384;
  }
}
