.evol-plus {
  color: #74cdcd;

  &::before {
    content: '\25B2';
  }
}

.evol-moins {
  color: #ff6384;

  &::before {
    content: '\25bc';
  }
}

.table {
  position: relative;

  td,
  th {
    vertical-align: middle;
    text-align: center;
  }

  thead {
    th {
      position: sticky;
      z-index: 1;
      top: 0;
      border-top: 0;
      border-bottom: 0;
      background-color: #f9f9f9;

      &.sortable-table-head {
        padding-right: 1.6em;
        cursor: pointer;

        &::after {
          color: #cbcbcc;
          content: '↓';
          position: absolute;
          right: 0.5em;
          top: 10px;
        }

        &::before {
          top: 10px;
          color: #cbcbcc;
          content: '↑';
          position: absolute;
          right: 1em;
        }

        &.sorting-asc {
          &::before {
            font-weight: bold;
            color: #35a2eb;
          }
        }

        &.sorting-desc {
          &::after {
            color: #35a2eb;
          }
        }
      }
    }
  }

  tbody {
    tr.row-toggle {
      height: 30px;
      cursor: pointer;
      position: relative;

      td:first-child::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
      }

      &:hover {
        td {
          background-color: #f9f9f9;
        }
      }

      &.active {
        td {
          background-color: #eee;

          &:first-child::before {
            background-color: #36f;
          }
        }
      }
    }
  }
}
